import { makeAutoObservable } from 'mobx'

class TestStore {
  constructor() {
    makeAutoObservable(this);
  }

  count: number = 1;
  
  increase() {
    this.count += 1;
  }

  decrease() {
    this.count -= 1;
  }
}


export default new TestStore()