import React from 'react';
import { BrowserRouter } from "react-router-dom";
import Loadable from "react-loadable";
import { Provider } from "mobx-react";
import zhCN from "antd/lib/locale-provider/zh_CN";
import { ConfigProvider } from "antd";

import * as stores from './store/index'
import Loading from "./components/Loading";
import './App.scss';
import BackTop from "./components/BackTop";

function App() {
  return (
    <Provider {...stores}>
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>
          <LoadBasic />
          <BackTop />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

const LoadBasic = Loadable(
  {
    loader: () => import("./layout"),
    loading: Loading,
  }
);

export default App;
