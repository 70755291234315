import React from "react"
import "./index.scss"

interface MainProps {
  [key: string]: any;
}

const BackTop: React.FC<MainProps> = (props): JSX.Element => {

  return(
    <div className="up">
      <img className="up-img"
           onClick={()=>{
             const maybeMyElement = document.getElementById('root');
             if (maybeMyElement) {
               const myElement = maybeMyElement!;
               myElement.scrollTop=0;
             }
           }}
           src="https://oss.elebuys.com/tmpdir/202104291053130003951411.png" alt=""/>
    </div>

  )
}
export default BackTop
