import React, { Component } from 'react';
import { Spin } from 'antd';
class Loading extends Component {

  render() {
    return (
      <div style={{ height: '100vh', width: '100%', display: 'flex',alignItems: 'center', justifyContent: 'center' }} >
        <Spin size="large" />
      </div>
    );
  }
}

export default Loading;
